import { graphql } from 'gatsby'

function addHashTo (item, hash) {
  for (const [key, value] of Object.entries(hash)) {
    item[key] = value
  }
  return item
}

function sdbm (str) {
  let arr = str.split('')
  arr = arr.reduce(
    (hashCode, currentVal) =>
      (hashCode = currentVal.charCodeAt(0) + (hashCode << 6) + (hashCode << 16) - hashCode),
    0
  )
  return `${arr}`
}

function compare (a, b) {
  if (a.dateOrder > 19000000 && b.dateOrder > 19000000) {
    if (a.dateOrder < b.dateOrder) { return 1 }
    if (a.dateOrder > b.dateOrder) { return -1 }
  }
  return 0
}

function process (items) {
  items.map((item) => {
    if (item.node && item.node.frontmatter) {
      item.id = sdbm(item.node.frontmatter.title)
      addHashTo(item, item.node.frontmatter)
      addHashTo(item, item.node.fields)
    } else if (item.frontmatter) {
      item.id = sdbm(item.frontmatter.title)
      addHashTo(item, item.frontmatter)
      addHashTo(item, item.fields)
    } else {
      addHashTo(item, item.node)
    }

    if (!item.tags) { item.tags = [] }
    if (item.mainType === 'blog') { item.tags.push(100) }

    return item
  })

  return items
}

function removeUnuniqueCases (items) {
  return items.reduce((accumulator, currentValue, index, array) => {
    if (!Array.isArray(accumulator)) {
      // First one is for "free" so you can compare.
      // We'll just add it, because we know there is no other yet
      accumulator = [accumulator]
    }

    const sameItem = accumulator.filter((item) => {
      const itemUrl = item.slug || item.overviewURL
      const currentValueUrl = currentValue.slug || currentValue.overviewURL
      return itemUrl === currentValueUrl
    })

    if (sameItem.length > 0 && sameItem[0].dateOrder > currentValue.dateOrder) {
      return accumulator
    }

    return [...accumulator, currentValue]
  })
}

export default (items, itemsAdd = [], itemsRemove = [], uniqueCases = false) => {
  items = process(items)
  itemsAdd = process(itemsAdd)

  items = (items.concat(itemsAdd)).sort(compare)

  if (itemsRemove.length > 0) {
    let removeIDs = itemsRemove.map(item => item.id)
    removeIDs = removeIDs.map(i => i.toString())
    items = items.filter(function (value, index, arr) {
      return !removeIDs.includes(value.id)
    })
  }

  if (uniqueCases) {
    return removeUnuniqueCases(items)
  }
  return items
}

export const projectQuery = graphql`
  fragment ProjectNodes on ProjectsJson {
    date(formatString: "MMMM DD, YYYY")
    id
    randomId
    title
    description
    overviewType
    tags
    dateOrder
    overviewImgGif
    overviewImg
    overviewImgSize {w, h}
    overviewType
    overviewURL
    overviewURLTextLong
    overviewURLText
    mainType
    subType
  }
`

export const blogQuery = graphql`
  fragment BlogNodes on Mdx {
    excerpt
    fields {
      slug
    }
    frontmatter {
      date(formatString: "MMMM DD, YYYY")
      title
      description
      overviewType
      tags
      dateOrder
      overviewImg {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      overviewType
      overviewURL
      overviewURLTextLong
      overviewURLText
      mainType
      subType
    }
  }
`
