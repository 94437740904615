import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

function SEO ({ description, lang, meta, keywords, title, imagesrc, imagefile, location, typeOG }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const defaults = data.site.siteMetadata

        location = location || (window ? window.location : false)
        const url = location.href
        const author = data.site.siteMetadata.author || 'You-et'
        description = description || defaults.description || 'I ate a clock yesterday, it was very time-consuming.'
        typeOG = typeOG || 'website' // website, article
        const cardTW = 'summary_large_image' // summary, summary_large_image

        let urlOrigin = (location ? location.origin : false) || 'https://www.you-et.com/'
        urlOrigin = urlOrigin ? urlOrigin.replace('https', 'http') : false // because https://stackoverflow.com/questions/8855361/fb-opengraph-ogimage-not-pulling-images-possibly-https
        let image = null
        if (imagesrc) {
          image = `${urlOrigin}${imagesrc}` // e.g. blog
        } else if (imagefile) {
          image = `${urlOrigin}/share-img/${imagefile}.png` // e.g. pages
        }

        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            titleTemplate={`%s | ${defaults.title}`}
            meta={[].concat(
              keywords.length > 0 ? {
                name: 'keywords',
                content: keywords.join(', ')
              } : []
            ).concat(meta)}
          >

            <title>{title}</title>
            <link rel="canonical" href={url} />
            <meta name="description" content={description} />
            {image && <meta name="image" content={image} />}

            <meta property="og:url" content={url} />
            <meta property="og:type" content={typeOG} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            {image && <meta property="og:image" content={image} />}
            {image && <meta property="og:image:secure_url" content={image.replace('http', 'https')} />}

            <meta name="twitter:card" content={cardTW} />
            <meta name="twitter:creator" content={author} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            {image && <meta name="twitter:image" content={image} />}

            <link rel="stylesheet" href="https://use.typekit.net/mbw8zil.css"/>
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: []
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
