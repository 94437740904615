import React from 'react'
import { Link } from 'gatsby'

import './footer.scss'

class Footer extends React.Component {
  render () {
    return (
      <footer>
        <Link to="/">You-et</Link> / <a href="https://www.linkedin.com/in/youettakunneman/">Linkedin</a> / <Link to="/project">UX Projects</Link> / <Link to="/blog">Blog</Link> - © {new Date().getFullYear()}
      </footer>
    )
  }
}

export default Footer
