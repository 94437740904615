import React from 'react'
import { StaticQuery, Link, graphql } from 'gatsby'

import ProcessBlogs from '../datacontent'
import './navigation.scss'

class Nav extends React.Component {
  render () {
    return (
      <StaticQuery
        query={graphql`
          query NavQuery {
            blogs: allMdx(
              sort: { fields: [frontmatter___date], order: DESC}
              filter: {frontmatter: {mainType: {nin: ["case", "project", "interactive"]}}}
            ) {
              edges {
                node {
                  ...BlogNodes
                }
              }
            }
            cases: allMdx(
              sort: { fields: [frontmatter___date], order: DESC },
              filter: {frontmatter: {mainType: {in: ["case", "project", "interactive"]}}}
            ) {
              edges {
                node {
                  ...BlogNodes
                }
              }
            }
            projectcases: allProjectsJson(
              sort: { fields: [dateOrder], order: DESC },
              filter: {mainType: {in: ["case", "interactive"]}, overviewURL: {regex: "/(blog)|(project)/"}},
            ) {
              edges {
                node {
                  ...ProjectNodes
                }
              }
            }
          }
        `}
        render={data => {
          const cases = ProcessBlogs(data.cases.edges, data.projectcases.edges, [], true).slice(0, 3)
          const blogs = ProcessBlogs(data.blogs.edges, [], data.cases.edges, true).slice(0, 3)

          const blogsList = blogs.map((post) => (
            <li key={`menu-${post.id}`}><Link to={post.overviewURL || post.slug}>{post.title}</Link></li>)
          )
          const casesList = cases.map((post) => (
            <li key={`menu-${post.id}`}><Link to={post.overviewURL || post.slug}>{post.title}</Link></li>)
          )

          return (
            <nav className="header-nav" key="page-nav">
              <div className="header-nav__wrapper">
                <h2><Link to="/">You-et</Link></h2>
                <div className="header-nav__columns">
                  <div>
                    <h3><Link to="/project">Projects</Link></h3>
                    <ul>
                      {casesList}
                    </ul>
                    <Link to="/project">and more ...</Link>
                  </div>
                  <div>
                    <h3><Link to="/blog">Blog</Link></h3>
                    <ul>
                      {blogsList}
                    </ul>
                    <Link to="/blog">and more ...</Link>
                  </div>
                  <div>
                    <h3>About me</h3>
                    <ul>
                      <li><Link to="/all">Museum</Link></li>
                      <li><a href="https://www.linkedin.com/in/youettakunneman/">Linkedin</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </nav>
          )
        }}
      />
    )
  }
}

export default Nav
